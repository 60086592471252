<template>
  <header class="page-header">
    <div class="page-header-inner">
      <img
        v-if="currentTheme !== 'TRUSTANA'"
        src="@/assets/imgs/header-logo-txt.png"
        alt=""
        class="6am-header-brand pointer"
        height="37px"
        @click="navigateTo('/')"
      />
      <img
        v-else
        src="@/assets/imgs/icons/trustana-header-logo.svg"
        alt=""
        class="6am-header-brand pointer"
        height="37px"
        @click="navigateTo('/')"
      />
      <div class="d-flex align-items-center">
        <DialogNotification />
        <div class="vertical-divider"></div>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <div
              class="user-auth-info d-flex align-items-center"
              v-bind="attrs"
              v-on="on"
            >
              <span class="user-name">{{ userFromAuth.name }}</span>
              <img
                src="@/assets/imgs/user-avatar.png"
                alt="user-avatar"
                class="user-avatar"
              />
            </div>
          </template>
          <v-list>
            <v-list-item class="logout-dropdown">
              <v-list-item-title
                class="pointer"
                @click="navigateTo('/user-info')"
                >My Account</v-list-item-title
              >
            </v-list-item>
            <v-list-item class="logout-dropdown">
              <v-list-item-title class="pointer" @click="confirmLogout"
                >Logout</v-list-item-title
              >
            </v-list-item>
            <!-- <v-list-item class="logout-dropdown">
              <v-list-item-title class="pointer" @click="removeToken"
                >DEV: Remove token</v-list-item-title
              >
            </v-list-item> -->
          </v-list>
        </v-menu>
      </div>
    </div>
  </header>
</template>

<script>
import DialogNotification from "@/components/DialogNotification"
import { mapGetters } from "vuex"
import routeMixins from "@/mixins/routeMixins"
export default {
  components: { DialogNotification },
  mixins: [routeMixins],
  data() {
    return {
      currentTheme: process.env.VUE_APP_THEME,
    }
  },
  computed: {
    ...mapGetters({
      userFromAuth: "userFromAuth",
    }),
  },
  methods: {
    confirmLogout() {
      this.$store.commit("SET_LOGOUT_POPUP", true)
    },
    removeToken() {
      this.$store.commit("SET_LOGOUT_USER")
      this.$store.commit("setCurrentToken", "")
    },
  },
}
</script>

<style lang="scss">
.page-header {
  display: block;
  width: 100%;
  height: 70px;
  background-color: #ffffff;
  border-bottom: 1px solid #cfd4d8;
  &-inner {
    height: 100%;
    padding: 0 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .vertical-divider {
    border-left: 1px solid #cfd4d8;
    height: 70px;
    margin-left: 24px;
    margin-right: 24px;
  }
  .user-auth-info {
    .user-name {
      font-size: 17px;
      line-height: 27px;
      letter-spacing: 0px;
      color: #000000;
      font-weight: 500;
    }
    .user-avatar {
      margin-left: 12px;
      width: 38px;
      height: 38px;
      object-fit: contain;
      border-radius: 100%;
    }
  }
}
</style>
