import { render, staticRenderFns } from "./SegmentCreate.vue?vue&type=template&id=25505db8&scoped=true&"
import script from "./SegmentCreate.vue?vue&type=script&lang=js&"
export * from "./SegmentCreate.vue?vue&type=script&lang=js&"
import style0 from "./SegmentCreate.vue?vue&type=style&index=0&id=25505db8&lang=scss&scoped=true&"
import style1 from "./SegmentCreate.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25505db8",
  null
  
)

export default component.exports