var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DefaultLayout',[_c('div',{staticClass:"h-100 first-wr-in-df"},[_c('div',{staticClass:"bread-crumb has-btn"},[_c('p',{directives:[{name:"show",rawName:"v-show",value:(!_vm.editGeneralInfo),expression:"!editGeneralInfo"}],staticClass:"bc-page__title"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('img',_vm._g(_vm._b({staticClass:"pointer mr-2",attrs:{"src":require("@/assets/imgs/icons/arrow-left-icon.svg"),"alt":"back-icon"},on:{"click":_vm.backToList}},'img',attrs,false),on))]}}])},[_c('span',[_vm._v("Back")])]),_vm._v(" "+_vm._s(_vm.isOnEdit ? "Edit" : "Detail")+" Segment: "+_vm._s(_vm.detail.name)+" ")],1),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.editGeneralInfo),expression:"editGeneralInfo"}],staticClass:"bc-page__title"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('img',_vm._g(_vm._b({staticClass:"pointer mr-2",attrs:{"src":require("@/assets/imgs/icons/arrow-left-icon.svg"),"alt":"back-icon"},on:{"click":_vm.backGeneralInfo}},'img',attrs,false),on))]}}])},[_c('span',[_vm._v("Back")])]),_vm._v(" Edit ")],1)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.editGeneralInfo),expression:"!editGeneralInfo"}],staticClass:"tab-control justify-content-between mb-3"},[_c('v-tabs',{staticClass:"segment-detail-tabs",attrs:{"active-class":'segment-detail-tabs-header__active',"hide-slider":true},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.items),function(item){return _c('v-tab',{key:item},[_vm._v(" "+_vm._s(item)+" ")])}),1)],1),_c('v-tabs-items',{staticClass:"tabs-wrapper companies-detail-tabs__wr",class:[
        _vm.editGeneralInfo ? 'companies-detail-tabs__fh' : '',
        _vm.createNewContact || _vm.createExistContact
          ? 'companies-detail-tabs__contacts'
          : '' ],model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',[_c('SegmentDataList',{attrs:{"current-detail":_vm.detail,"currentId":_vm.id,"on-create-new-contact":_vm.onCreateNewContact,"on-show-detail-contact":_vm.onShowDetailContact,"open-cf-delete":_vm.openCfDelete,"set-edit":_vm.setEdit}})],1),_c('v-tab-item',[(_vm.tab == 1)?_c('SegmentHistory',{attrs:{"current-detail":_vm.detail,"currentId":_vm.id,"updateCurrentData":_vm.updateCurrentData,"backAction":_vm.onCloseDetailContact}}):_vm._e()],1)],1),_c('ConfirmNormal',{attrs:{"content":_vm.deleteConfirmContent,"is-visible":_vm.showDeletePopup,"on-cancel":_vm.onCancelDelete,"on-confirm":_vm.onConfirmDelete,"on-loading":_vm.loadingDelete,"close-outside":true,"button-confirm-text":'Deactive'}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }