<template>
  <div
    class="ag-overlay-loading-center"
    style="background-color: transparent; border: none; box-shadow: none"
  >
    <img src="@/assets/imgs/icons/no-data-table.svg" alt="" />
  </div>
</template>

<script>
export default {}
</script>

<style></style>
