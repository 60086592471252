import { render, staticRenderFns } from "./CompanyOpportunities.vue?vue&type=template&id=63ab5b94&scoped=true&"
import script from "./CompanyOpportunities.vue?vue&type=script&lang=js&"
export * from "./CompanyOpportunities.vue?vue&type=script&lang=js&"
import style0 from "./CompanyOpportunities.vue?vue&type=style&index=0&id=63ab5b94&lang=scss&scoped=true&"
import style1 from "./CompanyOpportunities.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63ab5b94",
  null
  
)

export default component.exports