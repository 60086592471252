<template>
  <div
    class="ag-overlay-loading-center"
    style="background-color: lightsteelblue"
  >
    <i class="fas fa-hourglass-half"> {{ params.loadingMessage }} </i>
  </div>
</template>

<script>
export default {}
</script>

<style></style>
