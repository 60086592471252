var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DefaultLayout',[_c('div',{staticClass:"h-100 first-wr-in-df"},[_c('div',{staticClass:"bread-crumb has-btn"},[_c('p',{directives:[{name:"show",rawName:"v-show",value:(!_vm.editGeneralInfo),expression:"!editGeneralInfo"}],staticClass:"bc-page__title"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('img',_vm._g(_vm._b({staticClass:"pointer mr-2",attrs:{"src":require("@/assets/imgs/icons/arrow-left-icon.svg"),"alt":"back-icon"},on:{"click":_vm.backToList}},'img',attrs,false),on))]}}])},[_c('span',[_vm._v("Back")])]),_vm._v(" Company Detail: "+_vm._s(_vm.detail.name)+" ")],1),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.editGeneralInfo),expression:"editGeneralInfo"}],staticClass:"bc-page__title"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('img',_vm._g(_vm._b({staticClass:"pointer mr-2",attrs:{"src":require("@/assets/imgs/icons/arrow-left-icon.svg"),"alt":"back-icon"},on:{"click":_vm.backGeneralInfo}},'img',attrs,false),on))]}}])},[_c('span',[_vm._v("Back")])]),_vm._v(" Edit ")],1)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.editGeneralInfo),expression:"!editGeneralInfo"}],staticClass:"tab-control justify-content-between mb-3"},[_c('v-tabs',{staticClass:"company-detail-tabs",attrs:{"active-class":'company-detail-tabs-header__active',"hide-slider":true},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.items),function(item){return _c('v-tab',{key:item},[_vm._v(" "+_vm._s(item)+" ")])}),1),(_vm.tab === 0)?_c('div',{staticClass:"action-gr-btn d-flex align-items-center"},[_c('v-btn',{staticClass:"\n            btn-normal-text btn-actions-tabs\n            bc-btn\n            btn-font-weight-regular btn-font-color-66\n            mr-3\n          ",attrs:{"depressed":"","color":"#A7A7A700"},on:{"click":_vm.onEditGeneralInfo}},[_c('img',{staticClass:"mr-1",attrs:{"src":require("@/assets/imgs/icons/edit-gray-icon.svg"),"alt":"edit-gray-icon"}}),_vm._v(" Edit ")]),_c('v-btn',{staticClass:"\n            btn-normal-text btn-actions-tabs\n            bc-btn\n            btn-font-weight-regular btn-font-color-66\n          ",attrs:{"depressed":"","color":"#A7A7A700"},on:{"click":_vm.openCfDelete}},[_c('img',{staticClass:"mr-1",attrs:{"src":require("@/assets/imgs/icons/remove-gray-icon.svg"),"alt":"remove-gray-icon"}}),_vm._v(" Delete ")])],1):_vm._e()],1),_c('v-tabs-items',{staticClass:"tabs-wrapper companies-detail-tabs__wr",class:[
        _vm.editGeneralInfo ? 'companies-detail-tabs__fh' : '',
        _vm.createNewContact || _vm.createExistContact || _vm.tab === 2
          ? 'companies-detail-tabs__contacts'
          : '' ],model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',[(!_vm.editGeneralInfo)?_c('CompanyGeneralInfo',{attrs:{"current-detail":_vm.detail,"currentId":_vm.id}}):_c('CompanyGeneralInfoEdit',{attrs:{"current-detail":_vm.detail,"currentId":_vm.id,"updateCurrentData":_vm.updateCurrentData,"backGeneralInfo":_vm.backGeneralInfo}})],1),_c('v-tab-item',[(
            !_vm.showDetailContact && !_vm.createNewContact && !_vm.createFromExistContact
          )?_c('CompanyContact',{attrs:{"current-detail":_vm.detail,"currentId":_vm.id,"on-create-new-contact":_vm.onCreateNewContact,"on-create-from-exist-contact":_vm.onCreateFromExistContact,"on-show-detail-contact":_vm.onShowDetailContact}}):_vm._e(),(!_vm.showDetailContact && _vm.createNewContact)?_c('CompanyContactCreate',{attrs:{"current-detail":_vm.detail,"currentId":_vm.id,"updateCurrentData":_vm.updateCurrentData,"backAction":_vm.backContactInfo}}):_vm._e(),(!_vm.showDetailContact && _vm.createFromExistContact)?_c('CompanyContactCreateFromExist',{attrs:{"current-detail":_vm.detail,"currentId":_vm.id,"updateCurrentData":_vm.updateCurrentData,"backAction":_vm.backContactInfo}}):_vm._e(),(_vm.showDetailContact)?_c('CompanyContactDetail',{attrs:{"current-detail":_vm.detail,"currentId":_vm.id,"updateCurrentData":_vm.updateCurrentData,"backAction":_vm.onCloseDetailContact}}):_vm._e()],1),_c('v-tab-item',[(!_vm.createNewOppotunity)?_c('CompanyOpportunities',{attrs:{"current-detail":_vm.detail,"currentId":_vm.id,"on-create-new-contact":_vm.onCreateNewContact,"on-show-detail-contact":_vm.onShowDetailContact,"on-create-oppotunity":_vm.onCreateOppotunity}}):_vm._e(),(_vm.createNewOppotunity)?_c('CompanyOpportunitiesCreate',{attrs:{"current-detail":_vm.detail,"currentId":_vm.id,"updateCurrentData":_vm.updateCurrentData,"backAction":_vm.backListOpportunities}}):_vm._e()],1),_vm._l((3),function(item){return _c('v-tab-item',{key:item},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',{domProps:{"textContent":_vm._s(_vm.text)}})],1)],1)})],2),_c('ConfirmNormal',{attrs:{"content":_vm.deleteConfirmContent,"is-visible":_vm.showDeletePopup,"on-cancel":_vm.onCancelDelete,"on-confirm":_vm.onConfirmDelete,"on-loading":_vm.loadingDelete,"close-outside":true}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }